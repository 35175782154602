import { Controller } from "@hotwired/stimulus"

function ensureArray(someValue) {
  if (Array.isArray(someValue)) {
    return someValue
  }

  return [someValue]
}

function isFieldset(element) {
  return element.nodeName === "FIELDSET"
}

export default class extends Controller {
  static classes = [ "hidden", "active" ]
  static targets = [ "simpleTab", "abridgedTab", "foldedTab", "simpleForm", "abridgedForm", "foldedForm" ]

  static values = {
    initialTab: { type: String, default: "simple" }
  }

  connect() {
    if (this.initialTabValue === "abridged") {
      this.switchToAbridgedTab()
    } else if (this.initialTabValue === "folded") {
      this.switchToFoldedTab()
    } else {
      this.switchToSimpleTab()
    }
  }

  switchToSimpleTab() {
    this.#activate(this.simpleTabTarget)

    this.#deactivate([
      this.abridgedTabTarget,
      this.foldedTabTarget
    ])

    this.#showAndEnable(this.simpleFormTarget)

    this.#hideAndDisable([
      this.abridgedFormTarget,
      this.foldedFormTarget
    ])
  }

  switchToAbridgedTab() {
    this.#activate(this.abridgedTabTarget)

    this.#deactivate([
      this.simpleTabTarget,
      this.foldedTabTarget
    ])

    this.#showAndEnable(this.abridgedFormTarget)

    this.#hideAndDisable([
      this.simpleFormTarget,
      this.foldedFormTarget
    ])
  }

  switchToFoldedTab() {
    this.#activate(this.foldedTabTarget)

    this.#deactivate([
      this.simpleTabTarget,
      this.abridgedTabTarget
    ])

    this.#showAndEnable(this.foldedFormTarget)

    this.#hideAndDisable([
      this.simpleFormTarget,
      this.abridgedFormTarget
    ])
  }

  // Private
  #deactivate(targets) {
    const processedTargets = ensureArray(targets)
    processedTargets.forEach((target) => target.classList.remove(this.activeClass))
  }

  #activate(targets) {
    const processedTargets = ensureArray(targets)
    processedTargets.forEach((target) => target.classList.add(this.activeClass))
  }

  #hideAndDisable(targets) {
    const processedTargets = ensureArray(targets)
    processedTargets.forEach((target) => {
      target.classList.add(this.hiddenClass)

      if (isFieldset(target)) {
        target.disabled = true
      }
    })
  }

  #showAndEnable(targets) {
    const processedTargets = ensureArray(targets)
    processedTargets.forEach((target) => {
      target.classList.remove(this.hiddenClass)

      if (isFieldset(target)) {
        target.disabled = false
      }
    })
  }
}